<template>
  <div class="vContracts">

    <div class="container-fluid container-lg">
        <steps :steps="[]" />

        <div class="row align-items-center sticky mb-4">
          <div class="col">
            <ul class="selectRuntime row">
              <li class="col m-1" :class="{selected: selectedMainDir == dir}" v-for="dir in dirs" v-bind:key="dir.id" @click="selectMainDir(dir)">
                <span class="title">{{dir.bezeichnung_current_language}}</span>
              </li>
            </ul>
          </div>
        </div>
        <div class="row align-items-center sticky mb-4" v-if="selectedMainDir && selectedMainDir.children.length > 1">
          <div class="col">
            <ul class="selectRuntime small row align-items-center">
              <li class="col m-1" :class="{selected: selectedDir == subdir}" v-for="subdir in selectedMainDir.children" v-bind:key="subdir.id" @click="selectDir(subdir)">
                <span class="title">{{subdir.bezeichnung_current_language}} </span>
              </li>
            </ul>
          </div>
        </div>

      <div v-if="contracts.length" class="contracts">
            <ul class="selectContract row">
              <li class="col p-1 " :class="{selected: $store.state.contract == contract}" v-for="contract in contracts" v-bind:key="contract.id">

                <div class="inner d-flex flex-column h-100 pt-4 position-relative">
                  <div class="aktionsText bg-primary text-white px-1 text-center position-absolute text-uppercase"  style="top: 0; left: 0; right: 0;" v-if="contract.Aktion">
                    {{ contract.aktiontextcard_current_language }}
                  </div>

                  <div class="row">
                    <div class="col-12">
                        <span class="price" style="font-size: 2rem;"><span class="priceSign" style="display: inline-block;">{{contract.vertBeitrag | formatCurrency2 }}- <b>CHF</b></span><span v-if="contract.vertaktionstext_current_language" class="highlight" style="display: inline-block;" v-tooltip="contract.vertaktionstext_current_language">*</span></span>
                        <span class="highlight">{{contract.subtitle2_current_language}}</span>
                        <hr>
                        <div class="subtitle2" v-if="contract.monthly">
                          <span class="priceSign">{{contract.monthly.vertBeitrag | formatCurrency2 }}- <b>CHF</b></span> mtl. zahlbar
                        <hr>
                        </div>
                        <span class="title">{{contract.Bezeichnung}}</span>
                    </div>
                  </div>
                  <div class="row  mt-2 flex-grow-1">
                    <div class="col-12" v-if="contract.vertGruppeID == 60" v-html="contract.bullet_current_language"></div>
                    <div class="col-12 highlight my-3" v-if="contract.Aktion" style="font-size: 1.6rem; line-height:1.1em;color: #ff7500;">14-Tage Testen <br> für <strong>nur</strong> <span class="priceSign">1.- <b>CHF</b></span></div>
                  </div>
                  <div class="row" v-if="contract.vertaktionstext_current_language" >
                      <div class="col">
                        <a href="#" v-tooltip="contract.vertaktionstext_current_language" triggers="click">Details</a>
                      </div>
                  </div>

                  <div class="row mt-3">
                    <div class="col-12">
                        <button class="btn btn-primary btn-block text-uppercase"  @click="selectContract(contract)">einmal Zahlung ({{contract.id}})</button>
                        <button v-if="contract.monthly" class="btn btn-dark btn-block  text-uppercase"  @click="selectContract(contract.monthly)">monatliche Zahlung ({{contract.monthly.id}})</button>
                    </div>
                  </div>
                </div>
              </li>
            </ul>

      </div>

      <footer-link-bar></footer-link-bar>
    </div>

    <Footer v-if="false" @clickNext="onNextStep"  :button-enabled="footerButton()" :headline="footerHeadline()" :subheadline="footerSubHeadline()"></Footer>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Footer from "@/components/Footer";
import FooterLinkBar from "@/components/FooterLinkBar";
import Steps from "@/components/Steps";

export default {
  name: "Contracts",
  components: {Footer, FooterLinkBar, Steps},
  data(){
      return{
        coreHover: false,
        premiumHover: false,
        dirs: [],
        selectedDir: null,
        selectedMainDir: null,
        contracts: [],
        additions: [],
      }
  },
  mounted() {

    this.getDirs();
    this.$store.commit("SET_STEP", {step:1});
  },
  computed: {
    ...mapState(['studio', 'contract']),
  },
  watch:{
    '$i18n.locale': function(newVal, oldVal) {
      this.getDirs();
    },
    studio(){
      this.getDirs();
    },
    selectedDir(){
      this.getContracts();
    },
    selectedMainDir(){
      if((!this.selectedDir && this.selectMainDir && this.selectedMainDir.children.length > 0) ||
          this.selectedDir.father_id !=  this.selectedMainDir.id){
        this.selectedDir = this.selectedMainDir.children[0];
      }
    },
    contracts(){
      if(!this.contracts.length){
        this.bullets[6].core = {icon: true, value: "times"} ;
        return;
      }

      if(this.contracts[0].vertLaufzeit == 1){
        this.bullets[4].q = "pages.selectContract.planPoints.7";
        this.bullets[4].core = {icon: true, value: "check"};
        this.bullets[4].core = {text: true, icon: false, value: this.$tc("common.days", 10)};
        this.bullets[4].premium = {text: true, icon: false, value: this.$tc("common.days", 10)};
      }else{
        this.bullets[4].core = {icon: true, value: "times"};
        this.bullets[4].core = {text: true, icon: false, value: this.$tc("common.months", 1)};
        this.bullets[4].premium = {text: true, icon: false, value: this.$tc("common.days", 1)};
      }
      this.bullets[6].core = {icon: false, value: this.contracts[0].StartupBetrag} ;
      this.bullets[5].premium = {icon: false, value: 0} ;
      this.bullets[6].premium = {icon: false, value: 0} ;
    }
  },
  methods: {
    footerButton(){
        return (this.contract !== undefined && this.contract) ? true : false;
    },
    footerHeadline(){
      if(!this.footerButton()) return null;

      let value = parseInt(this.contract.vertBeitrag);
      if(parseInt(this.contract.StartupBetrag)){
        value += parseInt(this.contract.StartupBetrag);}

      return "CHF " + parseInt(value).toString();
    },
    footerSubHeadline(){
      if(!this.footerButton()) return null;

      return this.contract.Bezeichnung
    },
    onNextStep() {
      this.$store.commit('SET_STEP', {step: 2});
      if (typeof window.ga !== 'undefined'){window.ga('send', 'event', 'Bestellung', 'Vertrag gewählt', this.$store.state.contract.vertName);}
    },
    selectContract(contract) {
      //this.$store.commit("SET_CONTRACT", {contract: contract});
      this.$store.dispatch("setContract", {contract: contract});
      this.$emit('contractSelected', contract);
      this.onNextStep();
    },
    is($contract, type, length){

      if(!$contract){
        return false;
      }

      if(!this.contracts.length){
        return false;
      }

      if($contract.VertragSubTyp != type){
        return false;
      }

      if($contract.vertLaufzeit != length){
        return false;
      }

      return false;
      //return true;
    },
    isCore(){
      if(!this.contracts.length){
         return false;
      }

      if(this.contract == this.contracts[0]){
        return true;
      }

      return false;
    },
    isPremium(){
      if(!this.contracts.length){
        return false;
      }

      if(this.contract == this.contracts[1]){
        return true;
      }

      return false;
    },
    selectCore(){
        this.selectContract(this.contracts[0]);
        //console.log(this.contracts[0]);
        //if (typeof window.ga !== 'undefined'){window.ga('send', 'event', 'Bestellung', 'Vertrag gewählt', this.contracts[0].vertName);}
    },
    selectPremium(){
        this.selectContract(this.contracts[1]);
        //if (typeof window.ga !== 'undefined'){window.ga('send', 'event', 'Bestellung', 'Vertrag gewählt', this.contracts[1].vertName);}
    },
    getCorePrice(){
        return this.contracts[0].vertBeitrag;
    },
    getStrikeCorePrice(){
      if(this.prices[this.$store.state.studio.externTbcId]){
        return this.prices[this.$store.state.studio.externTbcId].core;
      }
      return this.contracts[0].vertBeitrag;
    },
    getPremiumPrice(){
        return this.contracts[1].vertBeitrag;
    },
    getStrikePremiumPrice(){
      if(this.prices[this.$store.state.studio.externTbcId]){
        return this.prices[this.$store.state.studio.externTbcId].premium;
      }
    },
    getDirs(){
      let url =  "/api/shop/studio/" + this.studio.id + "/dirs";
      //this.dirs = this.contracts = this.additions = [];

      this.selectedDir = null;
      this.$store.commit("SET_CONTRACT", {contract: null});
      this.$store.commit("SET_ADDITION", {addition: null});

      this.axios.get(url,{params:{lang: this.$i18n.locale}}).then(res => {
        this.dirs = res.data.dirs;

        if (!this.selectedDir) {
          this.selectedMainDir = this.dirs[0];
        }

        if (this.$root.config.dir) {
          this.dirs.forEach((currentValue) => {
              currentValue.children.forEach((cV) => {


                  if(cV.id == this.$root.config.dir){
                    this.selectMainDir(currentValue);
                    this.selectDir(cV);
                    //this.selectMainDir = currentValue;
                    //this.selectedDir = cV;
                  }
              });
          });
      }

      }).catch(error => {alert("Ein Fehler ist aufgetreten"); });
    },
    selectMainDir(dir){
      this.selectedMainDir = dir;
    },
    selectDir(dir){
        this.selectedDir = dir;
    },
    getContracts(){
      let url =  "/api/shop/studio/" + this.studio.id + "/dir/" + this.selectedDir.id + "/contracts";
      this.contracts = this.additions = [];
      this.$store.commit("SET_CONTRACT", {contract: null});
      this.$store.commit("SET_ADDITION", {addition: null});

      this.axios.get(url,{params:{lang: this.$i18n.locale}}).then(res => {
        this.contracts = res.data.contracts;

        if(!this.contract){
          this.$store.dispatch("setContract", {contract: this.contracts[1]});
        }




      }).catch(error => {alert("Ein Fehler ist aufgetreten"); });
    },
  }
}
</script>

<style scoped>

</style>