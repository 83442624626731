<template>
  <div class="vStudio flex-grow-1" :class="{'searchFocus': searchFocus}">
    <div class="h-100 d-flex flex-column">
    <h1 class="text-center slider" :class="{'closed': searchFocus}">{{ $t('pages.selectClub.headline') }}</h1>
    </div>
  </div>
</template>

<script>
import $ from "jquery/dist/jquery.min"
import {mapState} from "vuex";
import helper from "@/facebook";



export default {
  name: "Studios",
  components: {},
  computed: {
    ...mapState(["studio", "contract"]),
  },
  data(){
    return{
      searchFocus: false,
      mapView: false,
      searchTerm: "",
      studios: [],
      infoContent: '',
      infoOptions: {
        minWidth: 230,
        pixelOffset: {
          width: 0,
          height: 230
        }
      },
      infoWindowPos: {
        lat: 0,
        lng: 0
      },
      infoWinOpen: false,
      maps: {
        mapTypeControl: false,
        fullscreenControl: true,
        streetViewControl: false,
        styles: [{"featureType":"administrative","elementType":"labels.text.fill","stylers":[{"color":"#777777"}]}, {"featureType": "administrative.province","elementType": "all","stylers": [{"visibility": "off"}]},{"featureType":"landscape","elementType":"all","stylers":[{"color":"#f7f7f7"}]},{"featureType":"poi","elementType":"all","stylers":[{"visibility":"off"}]},{"featureType":"road","elementType":"all","stylers":[{"saturation":-100},{"lightness":45}]},{"featureType":"road.highway","elementType":"all","stylers":[{"visibility":"simplified"}]},{"featureType":"road.arterial","elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"transit","elementType":"all","stylers":[{"visibility":"off"}]},{"featureType":"water","elementType":"all","stylers":[{"color":"#e1e1e1"},{"visibility":"on"}]}]
      },
      center:{lat:46.856570, lng:8.218383}
    }
  },
  watch:{
    searchTerm(){
      this.loadStudios();
    }
  },

  mounted(){
    if(this.$root.config.modus != undefined && this.$root.config.modus == "employerApp"){
      this.$router.push('login');
      return;
    }

    this.$store.commit("SET_STEP", {step: 1});
    return;

    /*
    this.loadStudios();
    this.$store.commit("SET_STEP", {step: 0});*/
  },
  methods: {
    map(){
        this.mapView = true;
    },
    onStudioChanged(studio){
      helper.fbq('track', 'Lead');
      this.$store.dispatch("setStudio", {studio: studio});
      this.$emit("studioSelect", studio);
      this.$store.commit("SET_STEP", {step: 1});
    },
    loadStudios(){
      this.axios.get("/api/shop/studios",{params: {term: this.searchTerm}}).then(res => {
        this.studios = res.data.studios;
      }).catch(error => {alert("Ein Fehler ist aufgetreten"); });
    },
    toggleInfoWindow: function (marker, idx) {
      this.infoWindowPos = this.getMarkerPosition(marker);
      this.infoContent = this.getInfoWindowContent(marker);
      this.selectedStudio = marker;
      this.center = this.getMarkerPosition(marker);

      //check if its the same marker that was selected if yes toggle
      if (this.currentMidx == idx) {
        this.infoWinOpen = !this.infoWinOpen;
      }
      //if different marker set infowindow to open and reset current marker index
      else {
        this.infoWinOpen = true;
        this.currentMidx = idx;
      }

      $('.gm-style-iw-t .gm-style-iw.gm-style-iw-c').css('padding', 0);
    },
    getMarkerPosition(marker){
      return {lat: marker.coordsLat , lng: marker.coordsLng};
    },
    getMarkerIcon(marker){

      if(marker === this.selectedStudio){
        return{
          url: "img/kreuz_small_yellow.svg",
          anchor: {x: 15, y: 15},
          scaledSize: {width:30, height:30},
        };
      }

        return  {
              url: "img/kreuz_small.svg",
              anchor: {x: 15, y: 15},
              scaledSize: {width:30, height:30},
        };
    },
    selectStudio(studio){
      helper.fbq('track', 'Lead');
      this.$store.dispatch("setStudio", {studio: studio});
      //this.center = this.getMarkerPosition(studio);
      this.$emit("studioSelect", studio);
      this.$store.commit("SET_STEP", {step: 1});
    },

    getInfoWindowContent: function (marker) {

      return (
          `
        `);
    },
  }
}

</script>

<style scoped>

</style>